{
    "scripts": {
        "clean": "rm -rf ./dist",
        "dev": "parcel public/test.html",
        "docs": "typedoc src/index.ts",
        "prepublish": "npm run clean && tsc",
        "build": "tsc",
        "test": "jest --config ./jestconfig.js",
        "test:watch": "jest --config ./jestconfig.js --watchAll"
    },
    "name": "@giphy/js-fetch-api",
    "version": "4.7.1",
    "main": "dist/index.js",
    "description": "Javascript API to fetch gifs and stickers from the GIPHY API.",
    "homepage": "https://github.com/Giphy/giphy-js/tree/master/packages/fetch-api",
    "types": "dist/index.d.ts",
    "files": [
        "dist/**/*",
        "src/**/*"
    ],
    "license": "MIT",
    "publishConfig": {
        "access": "public"
    },
    "dependencies": {
        "@giphy/js-types": "^4.4.0",
        "@giphy/js-util": "^4.3.0",
        "qs": "^6.9.4"
    },
    "devDependencies": {
        "@types/qs": "^6.9.4",
        "jest-fetch-mock": "^3.0.3",
        "parcel-bundler": "latest",
        "typedoc": "^0.20.36",
        "typedoc-thunder-theme": "^0.0.2",
        "typescript": "^4.7.3"
    },
    "gitHead": "9c4a7e835c91f111d1b6796935e37c7d342d97fd"
}
