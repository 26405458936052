import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import { SpeechInputWithGifDisplay } from './SpeechInputWithGifDisplay';
import 'papercss/dist/paper.css';

function App() {
    return (
        <div>
            <h1>Weebo</h1>
            <SpeechInputWithGifDisplay />
        </div>
    );
}

ReactDOM.render(<SpeechInputWithGifDisplay />, document.getElementById('root'));
