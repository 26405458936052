import React, { useEffect, useState } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

import { Gif } from '@giphy/react-components';
import { GiphyFetch } from '@giphy/js-fetch-api';
import { IGif } from '@giphy/js-types';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';

import './styles/main.scss';
import { useDebounce } from './util';

// use @giphy/js-fetch-api to fetch gifs, instantiate with your api key
const gf = new GiphyFetch('YjeN8J1xqpBZ9ZJsXHx8gvaDBCQYHM8B');
const searchGif = (voice: string) => gf.search(voice);

type Command = {
    command: string[];
    callback: () => void;
};

export const SpeechInputWithGifDisplay = () => {
    const [shouldStop, setShouldStop] = useState(false);
    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    const commands: Command[] = [
        {
            command: ['Reset'],
            callback: () => resetTranscript()
        },
        {
            command: ['stop', 'please stop'],
            callback: () => {
                setShouldStop(true);
                setTimeout(() => {
                    SpeechRecognition.stopListening();
                }, 1000);
            }
        }
    ];


    const [gif, setGif]: [IGif | null, any] = useState(null);
    const [manualSearch, setManualSearch] = useState('');

    const debouncedTranscript = useDebounce(transcript, 1000);

    if (!browserSupportsSpeechRecognition) {
        return <span>Browser doesn't support speech recognition.</span>;
    }

    useEffect(() => {
        const fetchTheGif = async () => {
            commands.forEach((cmd) => {
                if (cmd.command.includes(debouncedTranscript.toLowerCase())) {
                    cmd.callback();
                }
            });
            if (debouncedTranscript) {
                // const gifResult = await searchGif('what');
                const gifResult = await searchGif(debouncedTranscript);
                const randomGifIdx = Math.floor(Math.random() * gifResult.data.length / 10);
                setGif(gifResult.data[randomGifIdx]);
                await resetTranscript();
                if (!shouldStop) {
                    setTimeout(() => {
                        SpeechRecognition.startListening({ continuous: true });
                    }, 100);
                }
            }
        }
        fetchTheGif();
    }, [debouncedTranscript, manualSearch]);

    const handle = useFullScreenHandle();

    return (
        <div>
            <button onClick={handle.enter}>Fullscreen</button>
            <FullScreen handle={handle} className={handle.active ? 'paper' : ''}>
                <div className='gif-container'>
                    {gif
                        ? <Gif
                            gif={gif}
                            width={'auto'}
                            height={window.innerHeight}
                            onGifClick={() => {
                                alert('manual');
                                setManualSearch(manualSearch + ' ')
                            }} />
                        : 'Turn on the Mic and start speaking to your new friend'
                    }
                </div>
            </FullScreen>
            <p>Microphone: {listening ? 'on' : 'off'}</p>
            <button onClick={() => {
                SpeechRecognition.startListening({ continuous: true });
                setShouldStop(false);
            }}>Start</button>
            <button onClick={SpeechRecognition.stopListening}>Stop</button>
            <div>
                <p>{transcript}</p>
                <p>{debouncedTranscript}</p>
            </div>
        </div>
    );
};
